.title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 0;
    padding: 18px 36px 0 36px;

    @media only screen and (max-width: 768px) {
        padding: 0px 24px;
    }
}